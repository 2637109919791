.Stripes{
    height:100%;
    width:100%;
    
    border-style: solid;
    border-color: black;
    border-width: 1px;
   

}
