.App {
  display: flex;
  align-items: center;
  
  justify-content: center;
  padding:20px;
  background-color: grey;
}

.Canvas {
  position: relative;
  width: 500px;
  height: 500px;
  min-width:500px;
  min-height:500px;

  display: block;
  margin:auto;
}

.ControlPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  margin: 5px;
  background-color: white;
}
.LibraryInfo{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  background-color: white;
}
.InfoPanel{
  width:50px;
  height: 50px;
  margin: 5px;
}
